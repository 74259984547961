/*!
URL: https://www.imensosoftware.com
Author: Imenso Software
*/
/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# App
--------------------------------------------------------------*/
body {
  font-size: 1em;
  font-family: "Roboto", sans-serif; }

a {
  color: #000; }
  a:hover {
    text-decoration: none; }

.chat-position {
  position: fixed;
  right: 50px;
  bottom: 50px;
  -webkit-box-align: end;
  -webkit-align-items: end;
      -ms-flex-align: end;
          align-items: end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  .chat-position .chat-button {
    height: 60px;
    width: auto;
    background: #4d49be;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: 0.3s all;
            transition: 0.3s all;
    padding: 0px 20px;
    color: #fff;
    font-weight: bold; }
    .chat-position .chat-button:hover {
      background: -webkit-linear-gradient(top, #4d49be, #4BC6E2);
      background: linear-gradient(to bottom, #4d49be, #4BC6E2); }
  .chat-position .chat-window-sml {
    border-radius: 15px;
    box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 22px 0px;
    padding: 30px;
    margin-bottom: 20px;
    width: 350px;
    border: 3px solid transparent;
    position: relative;
    background: -webkit-linear-gradient(white, white) padding-box, -webkit-linear-gradient(top, #4BC6E2, #4D49BE) border-box;
    background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #4BC6E2, #4D49BE) border-box; }
    .chat-position .chat-window-sml h2 {
      font-weight: normal;
      font-size: 1.4em; }

.bot-options {
  display: block; }
  .bot-options .option-btn {
    border: 1px solid #4d49be;
    padding: 7px 14px;
    margin-bottom: 10px;
    border-radius: 30px;
    text-align: center;
    font-size: 1em;
    display: block;
    color: #000;
    -webkit-transition: all ease .5s;
            transition: all ease .5s; }
    .bot-options .option-btn:hover {
      background-color: #4d49be;
      color: white;
      text-decoration: none; }

.more-options {
  color: #4d49be;
  text-decoration: underline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
      -ms-flex-pack: end;
          justify-content: end; }

.css-more-options {
  -webkit-transition: 0.3s all;
          transition: 0.3s all; }

.options-close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0; }

.chat-window-sml:hover .options-close {
  opacity: 0.5; }

.chat-window {
  width: 450px;
  border-radius: 20px !important;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 22px 0px;
  position: relative;
  border: 3px solid transparent;
  background: -webkit-linear-gradient(white, white) padding-box, -webkit-linear-gradient(top, #4BC6E2, #4D49BE) border-box;
  background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #4BC6E2, #4D49BE) border-box; }

.chatbot_button {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 5px rgba(138, 131, 214, 0.5);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer; }

.chatbot_button:hover {
  box-shadow: 0px 0px 20px 5px rgba(36, 166, 226, 0.5); }

.chatbox_parent {
  /* width: 500px;
  height: 750px; */
  height: 85%;
  background: #FFFFFF;
  border-radius: 20px !important
  /* border: 2.5px solid transparent;
  border-image: linear-gradient(45deg, red, yellow);
  border-image-slice: 1; */ }

.header {
  width: 100%;
  height: 55px;
  background: -webkit-linear-gradient(270deg, #4ac9e3 0%, #4d49be 100%);
  background: linear-gradient(180deg, #4ac9e3 0%, #4d49be 100%);
  border-radius: 15px 15px 0px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px; }

.header .cross {
  width: 30px;
  height: 30px;
  cursor: pointer; }

.logo_parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.milliennia {
  color: white;
  font-weight: 400;
  margin-left: 10px;
  letter-spacing: .5px;
  margin-bottom: 0; }

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.logo img {
  width: 25px; }

.chatbox_inside_body {
  width: 100%;
  height: 90%;
  /* border-radius: 0 0 18px 18px; */
  border-radius: 0 0 1em 1em;
  padding-right: 5px
  /* padding-top: 5px; */ }

.messege_section {
  position: relative;
  width: 100%;
  height: 60vh;
  /* background-color: violet; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  overflow-y: scroll; }
  .messege_section p {
    margin-bottom: 0; }

.left_flow {
  width: 100%
  /* background-color: tomato; */ }

.bot_msgs {
  max-width: 85%
  /* background-color: slategray; */ }

.bot_msg {
  min-width: 65px !important;
  min-height: 40px;
  background: #f0f4f7;
  border-radius: 4px;
  word-wrap: break-word;
  color: #000;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 20px; }

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5px; }

.bot_options {
  width: 100%;
  height: 40px;
  background: #55C2E2;
  border: 1.8px solid #F8F8F8;
  border-radius: 50px;
  margin: 5px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
  cursor: pointer; }

.bot_options:hover {
  background-color: white;
  color: #4BC6E2; }

.right_flow {
  /* background-color: springgreen; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
      -ms-flex-pack: end;
          justify-content: end; }

.user_msgs {
  /* position: absolute;
  right: 0; */
  color: #FFF;
  max-width: 85%
  /* background-color: slategray; */ }

.user_msg {
  min-width: 65px !important;
  min-height: 40px;
  background: #8adcec;
  border-radius: 4px;
  word-wrap: break-word;
  color: #000;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 20px; }

.msg_from_bot {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px; }

.time {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #858585; }

.right_flow .time {
  text-align: end; }

.typebox {
  width: 100%;
  height: 70px;
  /* background-color: coral; */
  border-radius: 0 0 1em 1em;
  border-top: 1px solid #DDD;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.typebox_input {
  width: 90%;
  height: 40px;
  background: #FFFFFF;
  border: 2.5px solid #4D49BE;
  border-radius: 30px;
  padding: 10px; }

.typebox_input::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #A8A8A8; }

.typebox_input::-moz-placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #A8A8A8; }

.typebox_input:-ms-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #A8A8A8; }

.typebox_input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #A8A8A8; }

.typebox_input:focus {
  outline: none;
  box-shadow: none; }

.typebox img {
  width: 30px;
  cursor: pointer; }

.new-chat-window {
  position: fixed;
  right: 520px;
  bottom: 30px;
  -webkit-box-align: end;
  -webkit-align-items: end;
      -ms-flex-align: end;
          align-items: end;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  .new-chat-window .chat-button {
    height: 60px;
    width: 60px;
    background: #4d49be;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: 0.3s all;
            transition: 0.3s all; }
    .new-chat-window .chat-button:hover {
      background: -webkit-linear-gradient(top, #4d49be, #8b84d7);
      background: linear-gradient(to bottom, #4d49be, #8b84d7); }
  .new-chat-window .chat-window-sml {
    border-radius: 15px;
    box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 22px 0px;
    margin-bottom: 20px;
    width: 450px;
    border: 3px solid transparent;
    position: relative;
    background: -webkit-linear-gradient(white, white) padding-box, -webkit-linear-gradient(top, #4BC6E2, #4D49BE) border-box;
    background: linear-gradient(white, white) padding-box, linear-gradient(to bottom, #4BC6E2, #4D49BE) border-box; }
    .new-chat-window .chat-window-sml h2 {
      font-weight: normal;
      font-size: 22px; }

.secured_box_info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f2f2f2; }
  .secured_box_info img {
    width: 50px; }

.px-15 {
  padding-left: 15px;
  padding-right: 15px; }

.font-small {
  font-size: 1em; }

.custom_form .form-group {
  margin-bottom: 10px; }
.custom_form label {
  margin-bottom: 0px; }
.custom_form .form-control {
  padding: .25rem .75rem; }
.custom_form .typebox_input {
  width: 100%;
  border-radius: 4px; }
.custom_form .input-group-text {
  border: 2.5px solid #4D49BE;
  border-right: inherit;
  background: #f2f2f2; }

.form-control:focus {
  border-color: #4DC4D8; }

.submit_btn .option-btn {
  background-color: transparent;
  cursor: pointer;
  padding: 7px 30px; }

.option_selected {
  background: #8adcec;
  border: 1px solid #8adcec;
  border-radius: 30px;
  padding: 7px 20px; }

.payment_success_icon {
  width: 40px;
  margin-bottom: 8px; }

.chat_btn {
  background: transparent; }

.chat_action_btn {
  background: transparent;
  color: #4D49BE;
  border: 1px solid #4D49BE;
  border-radius: 30px;
  padding: inherit;
  display: inline-block;
  color: #4D49BE;
  padding: 7px 30px;
  margin-bottom: 10px;
  -webkit-transition: all ease .5s;
          transition: all ease .5s; }
  .chat_action_btn:hover {
    background-color: #4d49be;
    color: white;
    text-decoration: none;
    border-radius: 30px; }

.f-bold {
  font-weight: bold; }

.chat_box_body {
  padding: 30px; }

.chat_box_header .header {
  border-radius: 10px 10px 0px 0px; }
.chat_box_header .options-close {
  color: #fff;
  top: 15px; }
.chat_box_header:hover .options-close {
  opacity: 1; }

.chat_close_icon i {
  color: #fff;
  border: 2px solid #E1E1E1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center; }
  .chat_close_icon i:hover {
    border: 2px solid #fff; }

.single_icon {
  color: #4D49BE;
  font-size: 2.5em !important; }

.cards_img img {
  width: 60%; }

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #999 #ffffff; }

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px; }

*::-webkit-scrollbar-track {
  background: #ffffff; }

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 19px;
  border: 3px solid #ffffff; }
